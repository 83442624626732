<template>
  <div class="flex flex-col min-h-screen font-sans bg-white">
    <Header />
    <Content />
    <Footer />
  </div>
</template>

<script>
import Header from "./Header.vue";
import Content from "./Content.vue";
import Footer from "./Footer.vue";

export default {
  name: "Layout",
  components: {
    Header,
    Content,
    Footer,
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
