<template>
  <div class="row">
    <div
      v-for="(column, columnIndex) in columns"
      :key="columnIndex"
      class="column"
    >
      <div
        v-for="(image, imageIndex) in column"
        :key="imageIndex"
        class="relative group"
      >
        <router-link v-if="image.link" :to="image.link">
          <img v-lazy="image.src" :alt="image.alt" class="w-full" />
          <div
            class="absolute inset-0 bg-black bg-opacity-30 opacity-0 opacity-100 flex items-end justify-center transition-opacity duration-300"
          >
            <span class="text-white text-3xl font-semibold banner-text mb-2">{{
              image.text
            }}</span>
          </div>
        </router-link>
        <img v-else v-lazy="image.src" :alt="image.alt" class="w-full" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageGrid",
  props: {
    images: {
      type: Array,
      required: true,
      validator(value) {
        return value.every((img) => img.src && img.alt && img.link && img.text);
      },
    },
  },
  data() {
    return {
      numberOfColumns: this.getNumberOfColumns(),
    };
  },
  computed: {
    columns() {
      const columns = Array.from({ length: this.numberOfColumns }, () => []);
      this.images.forEach((image, index) => {
        columns[index % this.numberOfColumns].push(image);
      });
      return columns;
    },
  },
  methods: {
    getNumberOfColumns() {
      return window.innerWidth < 801 ? 2 : 3;
    },
    updateNumberOfColumns() {
      this.numberOfColumns = this.getNumberOfColumns();
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateNumberOfColumns);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateNumberOfColumns);
  },
};
</script>

<style scoped>
.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

.column {
  flex: 33%;
  max-width: 33%;
  padding: 0 4px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

/* Responsive layout - makes a two column-layout instead of three columns */
@media screen and (max-width: 800px) {
  .column {
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    flex: 100%;
    max-width: 100%;
  }
}

/* Overlay styles */
.group {
  position: relative;
}

.group .absolute {
  position: absolute;
}
</style>
