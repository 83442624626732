<template>
  <main class="bg-white content-margin">
    <router-view></router-view>
  </main>
</template>

<script>
export default {
  name: "Content",
};
</script>

<style scoped>
.content-margin {
  margin-top: 6.8rem;
}

@media (max-width: 640px) {
  .content-margin {
    margin-top: 80px;
  }
}
</style>
