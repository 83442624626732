<template>
  <footer class="bg-brown text-white mt-auto">
    <div class="container p-4 flex flex-col md:flex-row justify-center">
      <!-- Column 1-->
      <div class="flex-1 text-left mr-0 md:mr-4">
        <h5 class="font-semibold text-xl">Coast to Coast Adventures</h5>
        <p class="italic mt-2 font-semibold">
          Costa Rica Hiking, Biking, Rafting Adventures
        </p>
        <p class="mt-2 font-semibold text-sm">
          With twenty five years experience, Coast to Coast Adventures designs
          and leads multi-activity vacations for private travellers, small and
          large groups, charities, families, student groups and just about
          anyone who wants to share in the natural beauty of Costa Rica.
        </p>
        <p class="mt-2 font-semibold text-sm">
          We take great pride in our attention to detail as well as the quality
          and safety of our varying adventures. For life-changing, inspirational
          journeys, the combination of the countless wonders of Costa Rica and
          the professionalism of Coast to Coast Adventures is the ultimate
          winning combination.
        </p>
      </div>
      <!-- Column 2 -->
      <div class="flex-1 text-left ml-0 mt-4 md:ml-4 md:mt-0">
        <router-link class="text-link text-xl font-semibold" to="/contact"
          >Contact Us</router-link
        >
        <p class="mt-2 font-semibold text-sm">
          If you have any queries please get in touch with us:
        </p>
        <p class="mt-2 font-semibold text-sm">
          Telephone
          <a class="text-link" href="tel:+50640012342">+506 4001-2342</a>
        </p>
        <p class="mt-2 font-semibold text-sm">
          Email
          <a class="text-link" href="mailto:office@coasttocoastadventures.com"
            >office@coasttocoastadventures.com</a
          >
        </p>
        <div class="w-full p-4">
          <div class="flex justify-center">
            <a
              href="http://atlasadventuretravel.com/"
              target="_blank"
              class="mx-2"
              ><img v-lazy="atlasBadge" alt="Image 1" class="max-h-24"
            /></a>
            <a
              href="http://www.adventuretravel.biz/"
              target="_blank"
              class="mx-2"
              ><img v-lazy="attaMemberBadge" alt="Image 2" class="max-h-28"
            /></a>
            <a href="https://www.lata.travel/" target="_blank" class="mx-2"
              ><img v-lazy="lataMemberBadge" alt="Image 3" class="max-h-28"
            /></a>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-dark-brown text-center mt-4 md:mt-8">
      &copy; 2024 Coast to Coast Adventures
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      atlasBadge: require("@/assets/logos/external/atlasBadge.png"),
      attaMemberBadge: require("@/assets/logos/external/attaMemberBadge.png"),
      lataMemberBadge: require("@/assets/logos/external/lataMemberBadge.jpg"),
    };
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
