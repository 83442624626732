<template>
  <div class="-mt-16">
    <!-- Hero Section -->
    <section
      class="relative h-screen transition-all duration-1000 ease-in-out z-0"
    >
      <div
        class="absolute inset-0 bg-cover bg-center parallax-image z-0 transition-background"
        :style="{
          backgroundImage: `url(${currentBackgroundImage})`,
          opacity: currentOpacity,
        }"
        ref="parallaxImage"
      ></div>
      <div
        class="absolute inset-0 bg-cover bg-center parallax-image z-0 transition-background"
        :style="{
          backgroundImage: `url(${nextBackgroundImage})`,
          opacity: nextOpacity,
        }"
      ></div>
      <div class="absolute inset-0 bg-black opacity-70"></div>
      <div
        class="container absolute inset-0 flex flex-col justify-center items-center text-white text-center px-6"
      >
        <h1 class="text-3xl md:text-6xl font-semibold mb-10 banner-text">
          Love staying active on vacation?
        </h1>
        <p class="text-xl md:text-4xl banner-text text-balance">
          <span class="font-semibold">You've come to the right place!</span> At
          Coast to Coast Adventures, we offer multi-sport activities set in
          Costa Rica's stunning natural landscapes. Join a scheduled adventure
          or let us craft a once-in-a-lifetime experience just for you!
        </p>
      </div>
    </section>

    <!-- Adventure Section -->
    <section class="pt-2 relative z-10 bg-white">
      <h2
        class="text-lg sm:text-2xl md:text-4xl text-center py-6 bg-brown text-white"
      >
        What kind of an adventure are you looking for?
      </h2>
      <div class="container">
        <ImageGrid :images="visibleAdventureImages" />
      </div>
    </section>

    <!-- Deals Section -->
    <section class="py-12 bg-gray-100">
      <h2
        class="text-lg sm:text-2xl md:text-4xl text-center py-6 bg-brown text-white"
      >
        Looking for a Deal?
      </h2>
      <div
        class="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
      >
        <TripCard
          v-for="deal in tripDeals"
          :key="deal.name"
          :promotionText="deal.overview.promotionText"
          :code="deal.code"
          :name="deal.name"
          :map="deal.overview.mapSrc"
          :length="deal.overview.length"
          :price="deal.overview.priceSection.primary.price"
          :sellText="deal.overview.sellText"
        />
      </div>
    </section>
  </div>
</template>

<script>
import tripData from "@/content/trips.js";
import { ref, onMounted, watch, onBeforeUnmount } from "vue";
import ImageGrid from "@/components/ImageGrid.vue";
import TripCard from "@/components/TripCard.vue";

export default {
  name: "LandingView",
  components: {
    ImageGrid,
    TripCard,
  },
  setup() {
    const tripDeals = tripData.tripsWithPromotions;
    const backgroundImages = [
      require("@/assets/hero/hero1.jpg"),
      require("@/assets/hero/hero2.jpg"),
      require("@/assets/hero/hero3.jpg"),
      require("@/assets/hero/hero4.jpg"),
      require("@/assets/hero/hero5.jpg"),
      require("@/assets/hero/hero6.jpg"),
    ];

    // Preload images
    backgroundImages.forEach((image) => {
      const img = new Image();
      img.src = image;
    });

    const currentBackgroundImage = ref(backgroundImages[0]);
    const nextBackgroundImage = ref(backgroundImages[1]);
    const currentOpacity = ref(1);
    const nextOpacity = ref(0);
    let currentImageIndex = 0;

    const changeBackgroundImage = () => {
      currentImageIndex = (currentImageIndex + 1) % backgroundImages.length;
      nextBackgroundImage.value = backgroundImages[currentImageIndex];
      nextOpacity.value = 1;
      setTimeout(() => {
        currentBackgroundImage.value = nextBackgroundImage.value;
        currentOpacity.value = 1;
        nextOpacity.value = 0;
      }, 800); // Match this duration with the CSS transition duration
    };

    const adventureImages = [
      {
        src: require("@/assets/collage/c1.jpg"),
        link: "/join-adventure",
        text: "Adventure Trips",
      },
      {
        src: require("@/assets/collage/c2.jpg"),
      },
      {
        src: require("@/assets/collage/c3.jpg"),
        link: "/family-trips",
        text: "Family Trips",
      },
      {
        src: require("@/assets/collage/c4.jpg"),
      },
      {
        src: require("@/assets/collage/c5.jpg"),
        link: "/custom-trips",
        text: "Custom Trips",
      },
      {
        src: require("@/assets/collage/c6.jpg"),
      },
      {
        src: require("@/assets/collage/c7.jpg"),
      },
      {
        src: require("@/assets/collage/c8.jpg"),
      },
      {
        src: require("@/assets/collage/c9.jpg"),
      },
      {
        src: require("@/assets/collage/c10.jpg"),
      },
      {
        src: require("@/assets/collage/c11.jpg"),
        link: "/adventure-plus",
        text: "Adventure Plus",
      },
      {
        src: require("@/assets/collage/c12.jpg"),
      },
    ];

    const visibleAdventureImages = ref([]);
    const parallaxImage = ref(null);

    const updateVisibleAdventureImages = () => {
      if (window.innerWidth < 801) {
        visibleAdventureImages.value = adventureImages.filter(
          (image) => image.link != undefined
        );
      } else {
        visibleAdventureImages.value = adventureImages;
      }
    };

    const handleScroll = () => {
      if (parallaxImage.value) {
        const scrollPosition = window.scrollY;
        parallaxImage.value.style.transform = `translateY(${
          scrollPosition * 0.5
        }px)`;
      }
    };

    onMounted(() => {
      setInterval(changeBackgroundImage, 10000);
      updateVisibleAdventureImages();
      window.addEventListener("resize", updateVisibleAdventureImages);
      window.addEventListener("scroll", handleScroll);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateVisibleAdventureImages);
      window.removeEventListener("scroll", handleScroll);
    });

    watch(() => window.innerWidth, updateVisibleAdventureImages);

    return {
      currentBackgroundImage,
      nextBackgroundImage,
      currentOpacity,
      nextOpacity,
      visibleAdventureImages,
      tripDeals,
      parallaxImage,
    };
  },
};
</script>

<style scoped>
.hero {
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.parallax-image {
  filter: blur(5px);
  transform: translateZ(0);
  will-change: transform;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.banner-text {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.87);
}

.transition-background {
  transition: opacity 0.8s ease-in-out;
}
</style>
