import { createRouter, createWebHistory } from "vue-router";
import LandingView from "../views/LandingView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: LandingView,
  },
  {
    path: "/about",
    name: "about",
    component: function () {
      return import(/* webpackChunkName: "about" */ "@/views/AboutView.vue");
    },
  },
  {
    path: "/our-camps",
    name: "our-camps",
    component: function () {
      return import(
        /* webpackChunkName: "our-camps" */ "@/views/OurCampsView.vue"
      );
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: function () {
      return import(
        /* webpackChunkName: "contact" */ "@/views/ContactView.vue"
      );
    },
  },
  {
    path: "/custom-trips",
    name: "custom-trips",
    component: function () {
      return import(
        /* webpackChunkName: "custom-trips" */ "@/views/CustomTripsView.vue"
      );
    },
  },
  {
    path: "/all-trips",
    name: "all-trips",
    component: function () {
      return import(
        /* webpackChunkName: "all-trips" */ "@/views/AllTripsView.vue"
      );
    },
  },
  {
    path: "/family-trips",
    name: "family-trips",
    component: function () {
      return import(
        /* webpackChunkName: "family-trips" */ "@/views/FamilyTripsView.vue"
      );
    },
  },
  {
    path: "/join-adventure",
    name: "join-adventure",
    component: function () {
      return import(
        /* webpackChunkName: "join-adventure" */ "@/views/JoinAnAdventureView.vue"
      );
    },
  },
  {
    path: "/adventure-plus",
    name: "adventure-plus",
    component: function () {
      return import(
        /* webpackChunkName: "adventure-plus" */ "@/views/AdventurePlusView.vue"
      );
    },
  },
  {
    path: "/trip/:tripId",
    name: "trip",
    component: function () {
      return import(/* webpackChunkName: "trip" */ "@/views/TripView.vue");
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView({ behavior: "instant" });
  },
});

export default router;
