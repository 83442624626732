<template>
  <router-link :to="`/trip/${code}`" class="block no-underline">
    <div class="trip-card max-w-sm overflow-hidden">
      <div class="bg-transparent p-2 text-center">
        <h3 v-if="promotionText" class="text-md font-medium text-light-brown">
          {{ promotionText }}
        </h3>
        <h2 class="text-2xl font-bold text-brown">{{ name }}</h2>
      </div>
      <div class="relative rounded-lg border-2 border-brown">
        <div class="relative">
          <img
            v-lazy="map"
            alt="Map"
            class="w-full h-auto object-cover rounded-md"
          />
          <div
            class="absolute bottom-0 left-0 p-2 text-brown flex flex-col items-start"
          >
            <span class="text-lg font-medium">{{ length }}</span>
            <span class="text-2xl font-bold">{{ price }}</span>
          </div>
        </div>
        <div
          class="p-4 bg-stone-50 text-black rounded-b-lg font-medium border-t-2 border-brown"
        >
          {{ sellText }}
        </div>
        <div
          class="bg-light-brown hover:bg-brown text-center text-white py-3 rounded-b-md text-xl font-medium"
        >
          Learn More
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "TripCard",
  props: {
    name: {
      type: String,
      required: true,
    },
    map: {
      type: String,
      required: true,
    },
    length: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
    sellText: {
      type: String,
      required: true,
    },
    code: {
      type: String,
      required: true,
    },
    promotionText: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped>
/* No custom CSS needed as we are using Tailwind classes */
</style>
