<template>
  <header class="fixed top-0 w-full z-50 duration-300">
    <nav class="flex items-center p-0 bg-white shadow-md hidden sm:block">
      <!-- Logo -->
      <div class="container">
        <router-link to="/">
          <img
            alt="Coast to Coast Adventures logo"
            src="@/assets/logos/logoFilled.png"
            class="h-27 ml-6 py-1 fixed"
        /></router-link>
      </div>

      <div class="flex-1 flex flex-col">
        <!-- First Row of Links -->
        <div class="bg-brown text-white h-7">
          <div class="container flex items-center justify-end px-4">
            <router-link class="text-white mx-2" to="/">Home</router-link>
            <router-link class="text-white mx-2" to="/all-trips"
              >All Trips</router-link
            >
            <router-link class="text-white mx-2" to="/our-camps"
              >Our Camps</router-link
            >
            <router-link class="text-white mx-2" to="/contact"
              >Contact Us</router-link
            >
            <router-link class="text-white mx-2" to="/about"
              >About Us</router-link
            >
          </div>
        </div>
        <!-- Second Row of Links -->
        <div class="bg-white text-brown h-20">
          <div
            class="container h-20 flex items-center justify-end px-4 space-x-4"
          >
            <router-link class="text-brown" to="/join-adventure"
              >Join an Adventure</router-link
            >
            <router-link class="text-brown" to="/family-trips"
              >Family Trips</router-link
            >
            <router-link class="text-brown" to="/custom-trips"
              >Custom Trips</router-link
            >
            <router-link class="text-brown" to="/adventure-plus"
              >Adventure Plus!</router-link
            >
          </div>
        </div>
      </div>
    </nav>
    <!-- Mobile Navigation -->
    <nav
      class="bg-white text-brown h-20 flex items-center justify-between px-4 sm:hidden"
    >
      <!-- Logo -->
      <router-link to="/"
        ><img
          alt="Coast to Coast Adventures logo"
          src="@/assets/logos/logoFilled.png"
          class="h-20 ml-2 py-2"
      /></router-link>

      <button @click="toggleMenu" class="text-brown focus:outline-none">
        <svg
          class="w-8 h-8"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16m-7 6h7"
          ></path>
        </svg>
      </button>
    </nav>
    <transition name="fade">
      <div
        v-show="menuOpen"
        class="bg-white sticky top-0 z-40 flex flex-col items-center justify-center space-y-6 sm:hidden shadow-md"
      >
        <router-link class="text-brown text-xl" @click="toggleMenu" to="/"
          >Home</router-link
        >
        <router-link
          class="text-brown text-xl"
          @click="toggleMenu"
          to="/all-trips"
          >All Trips</router-link
        >
        <router-link
          class="text-brown text-xl"
          @click="toggleMenu"
          to="/our-camps"
          >Our Camps</router-link
        >
        <router-link
          class="text-brown text-xl"
          @click="toggleMenu"
          to="/contact"
          >Contact Us</router-link
        >
        <router-link class="text-brown text-xl" @click="toggleMenu" to="/about"
          >About Us</router-link
        >
        <router-link
          class="text-brown text-xl"
          @click="toggleMenu"
          to="/join-adventure"
          >Join an Adventure</router-link
        >
        <router-link
          class="text-brown text-xl"
          @click="toggleMenu"
          to="/family-trips"
          >Family Trips</router-link
        >
        <router-link
          class="text-brown text-xl"
          @click="toggleMenu"
          to="/custom-trips"
          >Custom Trips</router-link
        >
        <router-link
          class="text-brown text-xl pb-4"
          @click="toggleMenu"
          to="/adventure-plus"
          >Adventure Plus!</router-link
        >
      </div>
    </transition>
  </header>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      menuOpen: false,
      lastScroll: 0,
      visible: true,
    };
  },
  watch: {
    menuOpen(newVal) {
      document.body.style.overflow = newVal ? "hidden" : "";
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    handleScroll() {
      const currentScroll = window.pageYOffset;
      if (currentScroll > this.lastScroll && currentScroll > 100) {
        this.visible = false;
      } else {
        this.visible = true;
      }
      this.lastScroll = currentScroll;
      document.querySelector("header").style.transform = this.visible
        ? "translateY(0)"
        : "translateY(-100%)";
    },
  },
};
</script>

<style scoped>
.h-27 {
  height: 6.75rem; /* This makes the height 27 (3.5rem * 7.71) */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.router-link-active,
.router-link-exact-active {
  color: #ff8f00;
}
</style>
